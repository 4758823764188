import { defaultClienteFirebase } from "../domain/model/clienteFirebase"
import animationData from '../../../assets/search.json';
// import _ from 'lodash'

class ClientePorAplicativoFirebaseController {
    clientes = {
        itens: []
    }
    projetos = {
        itens: []
    }
    itens = []
    itensTotal = []
    clienteFirebase = defaultClienteFirebase()
    projetoSelecionado = null
    clientesSelecionados = []
    animacao = { animationData: animationData }
    loading = false

    constructor(
        context,
        buscaClientesUseCase,
        buscaClientesPorAppUseCase,
        buscaProjetosUseCase,
    ) {
        this.context = context
        this.buscaClientesUseCase = buscaClientesUseCase
        this.buscaClientesPorAppUseCase = buscaClientesPorAppUseCase
        this.buscaProjetosUseCase = buscaProjetosUseCase
    }

    async filtrar() {
        try {
            this.itens = this.itensTotal
            if (this.clientesSelecionados.length > 0) {
                this.itens = this.itens.filter((item) => this.clientesSelecionados.includes(item.cod_cli));
            }

            if (this.projetoSelecionado) {
                this.itens = this.itens.filter((item) => item.apps.some((itemSome) => itemSome.firebase === this.projetoSelecionado));
            }
        } catch (error) {
            //TODO error
        }
    }


    async mounted() {
        try {
            this.loading = true
            this.clientes = await this.buscaClientesUseCase({ itemsPerPage: 1000, page: 1 })
            this.projetos = await this.buscaProjetosUseCase({ itemsPerPage: 1000, page: 1 })

            const response = await this.buscaClientesPorAppUseCase()
            const aux = [];
            for (const r of response) {
                for (const c of r.clientes) {
                    if (!(aux.filter(e => e.id === c.id).length > 0)) {
                        delete r.clientes;
                        c["apps"] = [];
                        c.apps.push(r);
                        aux.push(c);
                    } else {
                        const data = aux.find(function (ele) {
                            return ele.id === c.id;
                        });
                        data.apps.push(r);
                    }
                }
            }
            this.itensTotal = aux
            this.itens = aux;
        } catch (error) {
            //TODO error
        } finally {
            this.loading = false
        }
    }
}

export default ClientePorAplicativoFirebaseController