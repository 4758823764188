<template>
  <v-card
    v-if="!controller.loading"
    class="rounded-xl"
    elevation="0"
  >

    <v-card-title>
      Filtros
      <v-spacer></v-spacer>
      <div class="d-flex ">
        <v-select
          class="mr-2"
          v-model="controller.clientesSelecionados"
          label="Clientes"
          outlined
          multiple
          dense
          @change="controller.filtrar()"
          :items="controller.clientes.itens"
          item-text="nome"
          item-value="codCliCgi"
        ></v-select>

        <v-select
          label="Projeto"
          outlined
          dense
          clearable
          @change="controller.filtrar()"
          :items="controller.projetos.itens"
          item-text="nome"
          item-value="firebase"
          v-model="controller.projetoSelecionado"
        ></v-select>
      </div>
    </v-card-title>
    <v-card-text>
      <v-card elevation="0">
        <v-simple-table
          dense
          fixed-header
          class="elevation-0"
          height="calc(100vh - 220px)"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="min-width: 165px">Nome</th>
                <th style="min-width: 165px">Código cliente</th>
                <th
                  style="min-width: 80px"
                  class="text-center pa-2"
                  v-for="(projeto, k) of controller.projetos.itens"
                  :key="k"
                >
                  <div>
                    {{ projeto.nome }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cliente, c) of controller.itens"
                :key="c"
              >
                <td class="text-left">{{ cliente.nome_cli }}</td>
                <td class="text-left">{{ cliente.id }}</td>
                <td
                  v-for="(projeto, i) of controller.projetos.itens"
                  :key="i"
                >
                  <div>
                    {{ cliente.apps.filter(e => e.firebase === projeto.firebase).length > 0 ? "x" : "" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-card-text>
  </v-card>
  <div v-else>
    <div
      class="d-flex flex-column justify-center align-center font-weight-bold headline"
      style="height: calc(100vh - 100px)"
    >
      <div>
        Buscando todos os clientes do Firebase, isso pode demorar um pouco...
      </div>
      <dev-lottie
        class="mt-10"
        :options="controller.animacao"
        :height="300"
        :width="400"
      />
    </div>
  </div>
</template>

<script>
import { clientePorAppFirebaseController } from "@/di/di";
export default {
  data: (context) => ({
    controller: clientePorAppFirebaseController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>

<style scoped>
td,
th {
  border: 1px solid #dddddd;
  text-align: center;
}
</style>